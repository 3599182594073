import React, { Fragment } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import FooterAbout from "@/components/FooterAbout";
import FooterQuickLinks from "@/components/FooterQuickLinks";
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import darkTheme from "@/src/theme";
import Image from "next/image";
import Stack from "@mui/material/Stack";
import Link from "next/link";

const Footer = () => {
  
  const footerBottomBg = {
    background:`url("/images/footerBg.png") ${darkTheme.palette.primary.main}`,
    backgroundPosition:'center center',
    backgroundSize:'contain',
    padding: `10px 0 0 0`,
    backgroundRepeat:'repeat-x',
    '& h6':{
      fontSize: 12,
      color: darkTheme.palette.common.white,
      mr:2
    }
  }

  const footerContainer = {
    backgroundColor: darkTheme.palette.primary.main,
    minHeight: 400,
    padding: `70px 0 10px 0`,
  }

  return (
    <Fragment>
      <Typography component="div" sx={footerContainer}>
        <Container>
          <Grid container spacing={0}>               
            <Box>
              <Grid container spacing={{md:10, sm:4, xs:4}}>
                <Grid item xs={12} xl={4} lg={4} md={4} sm={12}>
                  <FooterAbout />
                </Grid>                            
                <Grid item xs={12} xl={8} lg={8} md={8} sm={12}>
                  <FooterQuickLinks />                
                </Grid>              
              </Grid>
            </Box>                                              
          </Grid>
        </Container>
        </Typography>
        <Typography component="div" sx={footerBottomBg}>
          <Stack display="flex" flexDirection="row" sx={{mr:2, pb:2, alignItems:'center', justifyContent:'flex-end'}}>
            <Typography variant="h6">Designed & Developed By</Typography>
            <Link href={`https://naidhruvatechnologies.com`}>
              <a target="_blank">
              <Image src={`https://naidhruvatechnologies.com/images/logo-main.png`} width={100} height={30} alt="naidhruva logo" title="Naidhruva Technologies" />
              </a>
            </Link>
          </Stack>
        </Typography>
    </Fragment>
  );
}

export default Footer