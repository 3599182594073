import React, { Fragment } from "react";
import Typography from '@mui/material/Typography'
import darkTheme from '@/src/theme';
import FooterLogo from '@/public/images/logo.png'
import Image from "next/image";
import Link from "next/link";

const footerAboutContainer = {           
  '& p': {
    fontSize: 14,
    marginTop: darkTheme.spacing(5),
    color: darkTheme.palette.primary.location,    
    fontWeight: 100,       
  },    
  '& a': {
    fontSize: 14,
    marginTop: darkTheme.spacing(5),
    color: darkTheme.palette.primary.alt,    
    fontWeight: 100,
    textDecoration:'none'       
  },            
};


const FooterAbout = () => {    
  return (    
    <div className="">           
      <Fragment>            
        <Typography component="div" sx={footerAboutContainer}>
          <Image src={FooterLogo} alt="logo" style={{textAlign:'center'}} height="85" width="55" />
          <Typography component="p">Welcome to Taste of Telugu, a brand born out of a deep-rooted passion for preserving the rich culinary heritage of Telugu cuisine. Our journey is more than just about running an online store; it is a story of tradition, culture, and the incredible flavours of Telugu land... <Link href="/about">know more</Link></Typography> 
        </Typography>            
      </Fragment>                 
    </div>              
  );
}
export default FooterAbout;