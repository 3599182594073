import React, { useState, useEffect, Fragment } from "react";
import Link from "next/link";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Image from "next/image";
import darkTheme from "@/src/theme";
import LogoImage from '@/public/images/logo.png';
import MobileNavMenu from '@/components/navMenus/MobileNavMenu'
import { nanoid } from "nanoid";
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { useCart } from "react-use-cart";
import { parseCookies } from "nookies";
import Cookies from "js-cookie";
import { useRouter } from 'next/router';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const headerContainer = {
  background:darkTheme.palette.primary.main,
  position: "fixed",
  top: "0",
  width: "100%",
  zIndex: 2,
  '& .mobileMode':{
    display:'none !important'
  },
  [darkTheme.breakpoints.down("md")]: {
    height:65,
    '& .mobileMode':{
      display:'block !important'
    }
  },
};

const logoContainer = {
  padding: 1,
  display: "flex",
  alignSelf: "center",
  position:'absolute',
  top:'-35px',
  left:0,
  background: darkTheme.palette.primary.main,
  padding: darkTheme.spacing(0.75),
  borderRadius: darkTheme.spacing(2),
  width:90,
  cursor:'pointer',
  [darkTheme.breakpoints.down("md")]: {
    top:'-25px',
    width:90,
    padding: darkTheme.spacing(1.5),
  },
  "& img":{
    zIndex: 999,
  }
};

const menuContainerList = {
  "& ul": {
    listStyle: "none",
    alignItems: "baseline",
    display: "flex",
    minHeight: 70,
    ml:10,
    "& li": {
      background: "transparent",
      margin: "auto 30px",
      color: darkTheme.palette.primary.contrastText,
      fontSize: 16,
      "&:last-child": {
        margin: "auto 0px auto 30px",
      },
      "& a": {
        color: darkTheme.palette.primary.contrastText,
        fontSize: 16,      
        position:'relative',
        textDecoration:'none',
        display:'flex',
        "&:hover": {
          color: darkTheme.palette.primary.alt,
          cursor: "pointer",
          background: "transparent",
        },
        "&:active": {
          color: darkTheme.palette.primary.alt,
          cursor: "pointer",
        },
      },      
      "& .active": {
        color: darkTheme.palette.primary.alt,
        cursor: "pointer",
      },
    },
  },
};

const menuContainer = {
  [darkTheme.breakpoints.down("md")]: {
    display: "none",
  },
};



const picklesDropdown = {
  background: darkTheme.palette.common.white,
  minWidth: "200px",
  top: `50px !important`
};



const Header = (props) => {
  const [loading, setLoading] = useState(true);
  const { isEmpty, totalUniqueItems } = useCart();
  const cookieUser = parseCookies();
  const [loggedInUser, setLoggedInUser] = useState();
  const Router = useRouter();

  const [anchorE2, setAnchorE2] = useState(null);
  const [anchorE3, setAnchorE3] = useState(null);

  const handleMenu = (event) => {
    setAnchorE2(event.currentTarget);
  };

  const handleDropDown = (event) => {
    setAnchorE3(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorE2(null);
  };

  const handleCloseDropDown = () => {
    setAnchorE3(null);
  };

  useEffect(() => {
    const new_user = cookieUser.globalUser ? JSON.parse(cookieUser.globalUser) : "";
    if (new_user) {
      setLoggedInUser(new_user);
    }
  },[]);

  const followLink = (event, link) => {
    event.preventDefault();
    setLoading(true);
    Router.push(link)
  }

  const logOutUser = (e) => {
    e.preventDefault();
    Cookies.remove("globalUser");
    setLoggedInUser(null);
    Router.push("/signin");
  };

  return (
    <Fragment>
      <Typography component="div" sx={headerContainer}>
        <Container>
          <Box 
            maxWidth="lg"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection:"row"
            }}
          > 
            <Typography component="div" sx={{position:'relative'}}>
              <Typography component="div" sx={logoContainer}>
                <Link href="/" style={{cursor:'pointer'}}> 
                    <Image
                      src={LogoImage}
                      alt="logo"
                      style={{ padding: 1 }}
                    />
                </Link>
              </Typography>                
            </Typography> 
            <Typography component="div" className="desktopMode">               
              <Typography component="div" sx={menuContainer}>
                <Typography component="div" sx={menuContainerList}>
                  <ul style={{margin:0}}>
                    <li key={nanoid(30)}>
                      <Link href={"/"}>Home</Link>
                    </li>
                    <li key={nanoid(30)}>
                      <a onClick={handleDropDown} aria-controls="pickles-menu" aria-haspopup="true">
                        <>Pickles&nbsp;<KeyboardArrowDownIcon /></>
                      </a>
                    </li>
                    {props.categories && props.categories.map((item)=>(
                      item.id != 1 && item.id != 5 && (
                        <>
                        {item.visible_menu == 1 && (
                          <li key={nanoid(30)}>
                            <Link href={'/collections/'+item.slug}>{item.title}</Link>
                          </li>
                        )}
                        </>
                    )))}
                                          
                    {!isEmpty ? (
                      <li>
                        <Link href={"/checkout"} passHref>
                          <a><ShoppingCartOutlinedIcon />{totalUniqueItems}</a>
                        </Link>
                      </li>
                    ):''}

                    {!loggedInUser ? (
                      <li key={nanoid(30)}>
                        <Link href={"/signin"}>Sign In</Link>
                      </li>
                    ):(
                      <li key={nanoid(30)}>
                        <Stack display="flex" alignItems="center" flexDirection="row">
                          <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="user-menu"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                          >
                            <Typography variant="h6" sx={{fontSize:13, alignItems:'center', display:'flex'}}>
                              <AccountCircle />
                            </Typography>
                          </IconButton>
                          <Typography 
                            component="a" 
                            onClick={handleMenu}
                            aria-controls="user-menu" 
                            aria-haspopup="true"
                            sx={{
                              cursor:'pointer',
                              alignSelf:'center'
                            }}
                          >
                            {loggedInUser.name}
                            <KeyboardArrowDownIcon />
                          </Typography>
                          </Stack>
                      </li>
                    )}

                  </ul>
                </Typography>
              </Typography>
            </Typography>
            <Typography component="div" className="mobileMode">
              {props.categories && <MobileNavMenu list={props.categories} totalUniqueItems={totalUniqueItems} /> }
            </Typography>
          </Box>    
          
          {/* user dropdown menu */}
          <Typography component="div">
            <Menu
                id="user-menu"
                anchorE1={anchorE2}
                open={Boolean(anchorE2)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                disableAutoFocusItem
                sx={{mt:5}}
              >
                <Typography component="div" sx={picklesDropdown}>
                  <MenuItem onClick={(event)=>followLink(event, '/account/profile')}>Profile</MenuItem>
                  <MenuItem onClick={(event)=>followLink(event, '/account')}>My Account</MenuItem>
                  <MenuItem onClick={(event)=>logOutUser(event)}>Sign Out</MenuItem>
                </Typography>
            </Menu> 
          </Typography>

          {/* pickles dropdown */}
          <Typography component="div">
            <Menu
              id="pickles-menu"
              anchorE1={anchorE3}                            
              open={Boolean(anchorE3)}
              onClose={handleCloseDropDown}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              transformOrigin={{ vertical: 'top', horizontal: 'center' }}
              disableAutoFocusItem
              sx={{mt:5}}
            >
              <Typography component="div" sx={picklesDropdown}>
                <MenuItem onClick={(event)=>followLink(event, '/collections/veg-pickles')}>Veg Pickles</MenuItem>
                <MenuItem onClick={(event)=>followLink(event, '/collections/non-veg-pickles')}>Non-Veg Pickles</MenuItem>
              </Typography>
            </Menu> 
          </Typography>
          
        </Container>
      </Typography>
    </Fragment>
  );
};
export default Header;
