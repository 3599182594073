import React from "react";
import { Stack } from '@mui/material'
import {Rings} from 'react-loader-spinner'
import darkTheme from "@/src/theme";

function loader() {
  return (
    <Stack justifyContent={'center'} alignItems={'center'} 
      sx={{
          height:'100vh', 
        }}
      >
        <Rings
            height="100"
            width="100"
            color={darkTheme.palette.primary.main}
            ariaLabel="watch-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            firstLineColor=""
            middleLineColor=""
            lastLineColor=""
            />
    </Stack>
  )
}

export default loader

