import React, { useEffect, useState, Fragment } from "react";
import Head from "next/head";
import { Typography } from "@mui/material";
import Header from "./common/Header";
import Footer from "./common/Footer";
import PageLoader from '@/components/loader'
import axios from "axios";

function Layout({ children }) {

  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);

  useEffect(()=>{
    axios.get(`/api/categories/listAll`,{
      headers:{
        'x-auth-token':process.env.JWT_TOKEN
      }
    }).then((response)=>{
      setCategories(response.data.result ? response.data.result : []);
      setLoading(false)
    })
  },[]);

    return (
      <Fragment>
        {loading ? (
          <PageLoader />
        ):(
          <Typography component="div">
            <Header categories={categories} />
            {children}  
            <Footer />                
          </Typography>
        )}
      </Fragment>
    );
  }

export default Layout;
