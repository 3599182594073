import React, { useState, useEffect }  from "react";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Link from "next/link";
import Typography from "@mui/material/Typography";
import darkTheme from "@/src/theme";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/Twitter';
import axios from 'axios';
import { nanoid } from "nanoid";

const FooterQuickLinks = () => {

  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [settings, setSettings] = useState(null);

  useEffect(()=>{
    fetchAllCategories();
    fetchSettings();
  },[]);



  const fetchAllCategories = async()=>{
    await axios.get(`/api/categories/listAll`,{
      headers:{
        'x-auth-token':process.env.JWT_TOKEN
      }
    }).then((response)=>{
      setCategories(response.data.result ? response.data.result : []);
      setLoading(false)
    })
  }

  const fetchSettings = async() => {
    await axios.get(`/api/settings`, {
        headers: {
          "x-auth-token": process.env.JWT_TOKEN,
        },
      })
      .then((response) => {     
        setSettings(response.data.result ? response.data.result[0] : []);
        setLoading(false)
      })
      .catch(err => {
        console.log(err);
      })
  }


  const footerLinks = {  
    mt: darkTheme.spacing(4), 
    '& h4':{
      color: darkTheme.palette.common.white,
      fontSize: 20,
      fontWeight: 700,
      mb: darkTheme.spacing(3),
      fontFamily: `'Playfair Display', serif`,
    },      
    '& p':{
      marginBottom: darkTheme.spacing(2) ,
      fontSize: 14,    
      color: darkTheme.palette.primary.location,    
      fontWeight: 100, 
    }, 
    '& a': {
      fontSize: 14,    
      color: darkTheme.palette.primary.location,    
      fontWeight: 100, 
      transition:`0.5s`,
      '&:hover':{
        pl:1,
        color: darkTheme.palette.primary.alt,    
        TextDecoration:'underline'
      }    
    },                
  };

  const iconStyle = {
    p:0.7, 
    borderRadius:5,
    width:35,
    height:35,
    background: darkTheme.palette.primary.main,
    color: darkTheme.palette.primary.alt,
    border: `2px solid ${darkTheme.palette.primary.alt}`,
    mr: darkTheme.spacing(3),
    transition:`0.5s`,
    '&:hover':{
      background: darkTheme.palette.primary.alt,
      color: darkTheme.palette.primary.main,
      border: `2px solid ${darkTheme.palette.primary.alt}`,
      cursor:'pointer'
    }
  }

  return (
    <Typography component="div">             
        <Grid container>                                       
          <Grid item xs={12} xl={3} lg={3} md={3} sm={6}>
            <Typography component="div" sx={footerLinks}>  
              <Typography variant="h4">Quick Links</Typography>  
                  <Typography component="p" key={nanoid(20)}>
                      <Link href={'/about'}>About Us</Link>
                  </Typography>
                  <Typography component="p" key={nanoid(20)}>
                      <Link href={'/contact'}>Contact Us</Link>
                  </Typography>
                  <Typography component="p" key={nanoid(20)}>
                      <Link href={'/privacy'}>Privacy Policy</Link>
                  </Typography>
                  <Typography component="p" key={nanoid(20)}>
                      <Link href={'/refund'}>Refund Policy</Link>
                  </Typography>
                  <Typography component="p" key={nanoid(20)}>
                      <Link href={'/terms'} >Terms & Conditions</Link>
                  </Typography>
              </Typography>                           
          </Grid>
          <Grid item xs={12} xl={3} lg={3} md={3} sm={6}>
            <Typography component="div" sx={footerLinks}>  
              <Typography variant="h4">Products</Typography>  
                                              
                {categories && categories.map((item)=>(
                   <Typography component="p" key={nanoid(20)}>
                        <Link href={'/collections/'+item.slug}>{item.title}</Link>
                    </Typography>
                ))}
            </Typography>                           
          </Grid>
          
          <Grid item xs={12} xl={6} lg={6} md={6} sm={6}>
            <Typography component="div" sx={footerLinks}> 
              <Typography variant="h4">Follow Us</Typography> 
              <Stack flexDirection="row" justifyContent="flex-start" sx={{mb:3}}>  
              {settings && settings['facebook_url'] ? (                                   
                <Typography component="p">
                    <Link href={settings['facebook_url']}>
                      <a target="_blank"><FacebookIcon sx={iconStyle} /></a>
                    </Link>
                </Typography>
              ):('')}
              {settings && settings['twitter_url'] ? (  
                <Typography component="p">
                    <Link href={settings['twitter_url']}>
                      <a target="_blank"><TwitterIcon sx={iconStyle} /></a>
                    </Link>
                </Typography>
              ):('')}
              {settings && settings['instagram_url'] ? ( 
                <Typography component="p">
                    <Link href={settings['instagram_url']}>
                      <a target="_blank"><InstagramIcon sx={iconStyle} /></a>
                    </Link>
                </Typography>
              ):('')}
              {settings && settings['youtube_url'] ? ( 
                <Typography component="p">
                  <Link href={settings['youtube_url']}>
                    <a target="_blank"><YouTubeIcon sx={iconStyle} /></a>
                  </Link>
              </Typography>
              ):('')}
              </Stack>
              <Typography variant="h4">Contact Us</Typography>
              {settings && settings['email'] ? (
              <Typography component="p">
                Email:  <Link href={`mailto:${settings['email']}`}>{settings['email']}</Link>
              </Typography>
              ):('')}
              {settings && settings['phone'] ? (
              <Typography component="p">
                Mobile:  <Link href={`tel:91${settings['phone']}`}>{settings['phone']}</Link>
              </Typography>
              ):('')}
              
            </Typography>                 
          </Grid>
        </Grid>         
    </Typography>
  );
};
export default FooterQuickLinks;
