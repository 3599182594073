import React, { useState, useEffect, Fragment } from "react";
import Layout from "../components/Layout";
import { useRouter } from "next/router";
import ErrorBoundary from "../components/ErrorBoundary";
import "../styles/globals.css";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from '@/src/theme';
import { CartProvider, useCart } from "react-use-cart";
import PageLoader from "@/components/loader";


export default function MyApp(props) {
  const { Component, pageProps } = props;
  const Router = useRouter();
  const [loading, setLoading] = useState(false);
  const { addItem, items, inCart, updateItemQuantity } = useCart();

  useEffect(() => {
    Router.events.on("routeChangeStart", () => checkRouter());
    Router.events.on("routeChangeComplete", () => setLoading(false));
    Router.events.on("routeChangeError", () => setLoading(false));
    return () => {
      Router.events.off("routeChangeStart", () => checkRouter());
      Router.events.off("routeChangeComplete", () => setLoading(false));
      Router.events.off("routeChangeError", () => setLoading(false));
    };
  }, [Router.events]);

  const checkRouter = () => {
    setLoading(true)
    // if(Router.pathname != `/product/[slug]`){
    //   setLoading(true)
    // }else{
    //   setLoading(false)
    // }
  }

  return (
    <Fragment>
      {loading ? (
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <PageLoader />
        </ThemeProvider>
      ) : (
        <ThemeProvider theme={theme}>
          <CssBaseline />
            <ErrorBoundary>
                <CartProvider
                  id="jamie"
                  onItemAdd={(item) => console.log(`Item ${item.id} added!`)}
                  onItemUpdate={(item) => console.log(`Item ${item.id} updated.!`)}
                  onItemRemove={() => console.log(`Item removed!`)}
                  >
                  <Layout>
                    <Component {...pageProps} />
                  </Layout>
                </CartProvider>
            </ErrorBoundary>
        </ThemeProvider>
      )}
    </Fragment>
  );
}
