import React, { Fragment, useState, useEffect } from 'react';
import Hamburger from 'hamburger-react'
import { Typography, Drawer, Stack, IconButton } from '@mui/material';
import Link from 'next/link';
import {darkTheme} from '@/src/theme';
import { nanoid } from 'nanoid';
import { parseCookies } from "nookies";
import AccountCircle from '@mui/icons-material/AccountCircle';
import { isEmpty } from 'lodash';

const muiDrawer = {  
  zIndex: 999,
  '& .MuiDrawer-paper': {
    background: darkTheme.palette.primary.main,
    borderLeft:`1px solid #red`,
    maxWidth: '33%',
    width: '33%',
    paddingBottom: 12,
    [darkTheme.breakpoints.down("md")]: {
      maxWidth: '80%',
      width: '80%',
    },
  }
};

const HamburgerStyle = {
  '& .hamburger-react':{
    mt:1,
    background:darkTheme.palette.primary.alt,
    padding:'6px',
    borderRadius:50,
    color: darkTheme.palette.primary.main,
    zIndex:9999,
  }
}
const menuContainer = {
  // height:'100vh',
  mt:15,
  ml:11,
  display:'flex',
  alignItems:'start',
  flexDirection:'column',
  justifyContent:'center',
  textAlign:'left',
  '& a':{
    color:'#fefefe',
    fontSize:18,
    fontWeight:100,
    mb:3.5,
    display:'block',
    textAlign:'left',
    transition:'0.5s',
    textTransform:'capitalize',
    '&:hover':{
      color: darkTheme.palette.primary.alt,
      transition:'0.5s',
    }
  },
  '& .active':{
    transition:`0.75s` ,
    color: darkTheme.palette.primary.alt,
  },
}


function MobileNavMenu(props) {
  const [isOpen, setOpen] = useState(false);
  const cookieUser = parseCookies();
  const [loggedInUser, setLoggedInUser] = useState(false);
  
  useEffect(() => {
    const new_user = cookieUser.globalUser ? JSON.parse(cookieUser.globalUser) : "";
    if (new_user) {
      setLoggedInUser(new_user);
    }
  },[]);

  const handleDrawerClose = () => {
    setOpen(false)
  }


  return (
    <Fragment>
      <Typography component="div" sx={isOpen ? {marginRight:-10, ...HamburgerStyle} : {...HamburgerStyle}}>
        <Hamburger toggled={isOpen} toggle={setOpen} />
      </Typography>  
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={() => null}
        ModalProps={{
            keepMounted: false,
        }}
        hideBackdrop={false}         
        sx={{
            ...muiDrawer,
            backdropFilter: "blur(5px)",
        }}
        onEscapeKeyDown={handleDrawerClose}
      >
        <Stack display="flex" alignItems="space-between" justifyContent="flex-end">
          <Typography component="div" sx={{...HamburgerStyle, position:'absolute', right:12, top:2}}>
            <Hamburger toggled={isOpen} toggle={setOpen} />
          </Typography>
          <Typography component="div" sx={menuContainer}>
              <Typography component="div">                   
                <Link href={"/"} >Home</Link>  
                {props.list && props.list.map((item, index)=>(
                  <Link href={'/collections/'+item.slug} key={nanoid(30)}>{item.title}</Link>
                ))}                    
                {!loggedInUser ? (
                  <Link href={"/signin"}>Sign In</Link>
                ):(
                  <Link href={"/account"}>
                    <Typography variant="span" 
                      sx={{
                        cursor:'pointer', 
                        fontSize:18, 
                        color:'#fefefe', 
                        alignItems:'center', 
                        display:'flex',
                        '&:hover':{
                          color: darkTheme.palette.primary.alt
                        }
                      }}
                    >
                      <AccountCircle />&nbsp;{loggedInUser.name}
                    </Typography>
                  </Link>
                )} 
                {props.totalUniqueItems >0 ? (                                                 
                <Link href="/checkout">
                  <a>
                    Checkout
                    <IconButton 
                      sx={{
                        background:darkTheme.palette.common.white,
                        borderRadius:10,
                        ml:2,
                        p:0.25,
                        fontSize:14,
                        fontWeight:900,
                        height:30,
                        width:30
                      }}
                    >{props.totalUniqueItems}
                    </IconButton>
                  </a>
                </Link>
                ):('')}
              </Typography>
          </Typography>
        </Stack>
      </Drawer>
    </Fragment>
  )
}

export default MobileNavMenu