import { createTheme } from '@mui/material';

const fontWeightLight = 300;
const fontWeightRegular = 400;
const fontWeightMedium = 600;
const fontWeightBold = 800;

const black = "#000";
const white = "#fff";
const gray = "#aaaaaa";
const light = "#545454";


export const darkTheme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 991,
        lg: 1200,
        xl: 1536,
      },
    },
    palette: {
      mode: 'light',   
        common: {
          black: `${black}`,
          white: `${white}`,
          gray: `${gray}`,
          light: `${light}`,
        },
        primary: {
          main: '#0f524e',
          contrastText: '#fff',
          location: '#CECECE',
          title: '#F6F6F6',         
          alt: '#fdd00c',
          light: '#ffffff90',          
        },
        secondary:{
          main : '#050505E5',
        },
        buttons: {
            main: '#0f524e',
            alt: '#fdd00c',
            light: '#ffffff90',
        },
        error: {
            main: '#19857b',
        },
        background: {
            default: '#efefef',
            alternate: '#1D1D1D',
            primary: '#1D1D1D',
            secondary: '#343434',
        },              
    },    
    typography: {
        "margin":"0 auto",
        "fontFamily": `"Poppins", sans-serif`,
        "fontSize": 14,
        "fontWeight": fontWeightRegular,   
        h1: {
          fontSize: 30,  
          fontWeight: fontWeightBold,         
        },
        h2: {
          fontSize: 28,  
          fontWeight: fontWeightBold,         
        },
        h3: {
          fontSize: 26,  
          fontWeight: fontWeightMedium,         
        },
        h4: {
          fontSize: 24,  
          fontWeight: fontWeightMedium,         
        },
        h5: {
          fontSize: 22,  
          fontWeight: fontWeightLight,         
        },
        h6: {
          fontSize: 20,  
          fontWeight: fontWeightLight,         
        },
        subtitle1: {
          fontSize: 12,
        },        
    },  
    components:{
      MuiPaper: {
        styleOverrides: {
          root: {
            // background: 'transparent',
            maxWidth: '100%',
          }
        }
      },
    }
});

export default darkTheme;


